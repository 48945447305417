<template>
  <div class="border border-solid mt-5 border-blue-200">
    <div class="flex">
      <div class="duration-300 w-full">
        <datatable
          ref="table"
          :data="cashbacks"
          :columns="columns"
          :fillable="false"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["profile"],
  data() {
    return {
      columns: [
        {
          name: "reference",
          th: "Reference",
        },
        {
          name: "type",
          th: "Type",
        },
        {
          name: "commission",
          th: "Commission",
          render: (cashback) =>
            cashback?.type === "percentage"
              ? `${cashback?.commission}%`
              : cashback?.commission,
        },
        {
          name: "amount",
          th: "Amount",
          render: (cashback) =>
            this.$options.filters.formatAmount(cashback?.amount),
        },
        {
          name: "cashback",
          th: "Cashback",
          render: (cashback) =>
            this.$options.filters.formatAmount(cashback?.cashback),
        },
        {
          name: "status",
          th: "Status",
          render: (cashback) => {
            switch (cashback?.status) {
              case "pending":
                return `<div class="badge badge-orange-soft-outline px-7">
                  Pending
                </div>`;
              case "paid":
                return `<div class="badge badge-green-soft-outline px-7">
                  Paid
                </div>`;
              default:
            }
          },
        },
        {
          name: "paid_at",
          th: "Paid At",
          render: (cashback) =>
            this.$moment(cashback?.paid_at).format("dddd, MMMM Do YYYY"),
        },
      ],
    };
  },
  computed: {
    cashbacks() {
      return this.profile?.cashbacks || [];
    },
  },
};
</script>